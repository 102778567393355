<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <DetachmentsOfflineOnlineForm
      :showHeader="false"
      @close="$router.push({name: 'DetachmentsOfflineOnlineList'})"
      />
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import DetachmentsOfflineOnlineForm from '../components/forms/DetachmentsOfflineOnlineForm'
import sections from './defaultNavigationDrawerSections'
import { mapActions } from 'vuex'

export default {
  name: 'DetachmentsOfflineOnlineDetail',
  components: { DetachmentsOfflineOnlineForm, Layout },
  data () {
    return {
      title: this.$t('detachments.detachmentsActionEdit'),
      sections: sections,
      detachmentId: this.$route.params.id

    }
  },
  mounted () {
    this.getDetachmentOfflineOnline(this.detachmentId)
  },
  methods: {
    ...mapActions('detachmentsOfflineOnline', ['getDetachmentOfflineOnline'])
  }
}
</script>

<style scoped>

</style>
