<template>
  <div class="d-flex float-right">
    <v-tooltip bottom v-if="adminUrlDetailView">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" :href="adminUrlDetailView" target="_blank" color="purple" outlined class="elevation-1">
          <v-icon>fa-database</v-icon>
        </v-btn>
      </template>
      <span>Data Change Admin</span>
    </v-tooltip>
    <v-breadcrumbs :items="items" class="body-2 mt-n2">
      <template v-slot:divider>
        <v-icon color="grey lighten-2">fa-chevron-right</v-icon>
      </template>
      <template v-slot:item="props">
        <router-link
          :to="{ name: props.item.name, params: {id: props.item.id ? props.item.id : undefined }}"
          :class="[props.item.disabled && 'disabled']">
          {{ props.item.text.toUpperCase() }}
        </router-link>
      </template>
    </v-breadcrumbs>
    </div>
</template>

<script>
import { BASE_URL } from '@/variables'

export default {
  name: 'BreadcrumbsField',
  props: {
    items: Array,
    adminUrl: String
  },
  computed: {
    adminUrlDetailView () {
      return this.adminUrl ? BASE_URL + this.adminUrl : null
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled {
    color: grey;
    pointer-events: none;
  }
  a {
    text-decoration: none;
  }
  a:hover{
    color: var(--anchor);
  }
</style>
