/**
 * This file define the list with the default menu displayed by the NavigationDrawerComponent
 */

export default [
  {
    title: 'Campañas',
    icon: 'fa-bullhorn',
    name: 'CampaignList'
  },
  {
    title: 'Cargos',
    icon: 'fa-sticky-note',
    name: 'NotesListView'
  },
  {
    title: 'Detalle de Cargo',
    icon: 'fa-sticky-note',
    name: 'NoteDetailView'
  }
]
