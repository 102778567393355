<template>
  <v-container>
    <BreadcrumbsField v-if="!showHeader" :items="itemsBreadcrumbs"/>
    <v-card>
      <v-form @submit.prevent="saveForm" ref="form">
        <v-card-title v-if="showHeader">
          <span class="headline">Nueva extravisibilidad</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="!showHeader"
                  :label="$t('detachments.codeName')"
                  v-model="code_name"
                  disabled
                />
                <v-text-field
                  :label="$t('general.name')"
                  v-model="verbose_name"
                  :rules="[isEmptyRule]"
                />
                <v-select
                  v-model="schema_name"
                  :items="detachmentsOptions"
                  :label="$t('general.type')"
                  dense
                  :rules="[isEmptyRule]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="$t('general.aggressiveness')"
                  v-model="aggressiveness"
                  :rules="[isNumberRule]"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="$t('general.weight')"
                  v-model="weight"
                  :rules="[isNumberRule]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="cancel" color="primary" text @click="closeForm">Cancelar</v-btn>
          <v-btn class="save" color="primary" @click="saveForm">Guardar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validationMixin } from '@/apps/core/mixins/validations'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'
import { RESPONSE_LEVEL } from '@/variables'

export default {
  name: 'DetachmentsOfflineOnlineForm',
  components: { BreadcrumbsField },
  props: {
    showHeader: Boolean
  },
  mixins: [validationMixin],
  data () {
    return {
      textAlert: '',
      showAlert: false
    }
  },
  created () {
    this.getDetachmentOfflineOnlineOption()
  },
  computed: {
    ...mapState('detachmentsOfflineOnline', ['currentItem', 'detachmentsOptions']),
    itemsBreadcrumbs () {
      return [
        {
          text: this.$t('detachments.breadcrumbList').toUpperCase(),
          disabled: false,
          name: 'DetachmentsOfflineOnlineList'
        },
        {
          text: this.$t('detachments.breadcrumb').toUpperCase(),
          disabled: true
        }
      ]
    },
    code_name: {
      get () {
        return this.currentItem.code_name
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, code_name: value })
      }
    },
    verbose_name: {
      get () {
        return this.currentItem.verbose_name
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, verbose_name: value })
      }
    },
    schema_name: {
      get () {
        return this.currentItem.schema ? this.currentItem.schema.name : ''
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, schema_name: value })
      }
    },
    aggressiveness: {
      get () {
        return this.currentItem.props ? this.currentItem.props.aggressiveness : ''
      },
      set (value) {
        this.currentItem.props = { ...this.currentItem.props, aggressiveness: value }
        this.updateCurrentItem({ ...this.currentItem })
      }
    },
    weight: {
      get () {
        return this.currentItem.props ? this.currentItem.props.weight : ''
      },
      set (value) {
        this.currentItem.props = { ...this.currentItem.props, weight: value }
        this.updateCurrentItem(this.currentItem)
      }
    }
  },
  methods: {
    ...mapActions('detachmentsOfflineOnline', ['putFormItem', 'updateCurrentItem', 'getDetachmentOfflineOnlineOption',
      'createDetachmentOfflineOnline', 'reloadItemList']),
    ...mapActions({ addNotification: 'addNotification' }),
    closeForm () {
      this.$refs.form.reset()
      this.updateCurrentItem({})
      this.$emit('close')
    },
    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },
    saveForm () {
      if (this.$refs.form.validate()) {
        if (this.currentItem.id === undefined) {
          this.createDetachmentOfflineOnline()
            .then(response => {
              this.displayNotification(response.data ? response.data.alert_message : response)
              this.reloadItemList()
            })
        } else {
          this.putFormItem(this.currentItem)
            .then(response => {
              const alertMessage = {}
              if ('alert_message' in response.data) {
                alertMessage['level'] = response.data.alert_message.level
                alertMessage['message'] = response.data.alert_message.message
              } else {
                alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
              }
              this.displayNotification(alertMessage)
            })
        }
      }
    },
    displayNotification (alertMessage) {
      if (alertMessage.level === RESPONSE_LEVEL.SUCCESS) {
        if (alertMessage.message) this.addNotification(createSuccessNotification(alertMessage.message))

        this.$emit('save')
        this.$refs.form.reset()
        this.closeForm()
      } else if (alertMessage.level === RESPONSE_LEVEL.WARNING) {
        this.addNotification(createWarningNotification(alertMessage.message))
      } else if (alertMessage.level === RESPONSE_LEVEL.ERROR) {
        this.addNotification(createErrorNotification(alertMessage.message))
      } else {
        this.addNotification(createErrorNotification(this.$t('general.errorSomethingHappened')))
      }
    }
  },
  updated () {
    this.$refs.form.resetValidation()
  },
  beforeDestroy () {
    this.$refs.form.reset()
    this.updateCurrentItem({})
  }
}
</script>

<style scoped>

</style>
