import i18n from '@/i18n'
const COLORPATTERN = RegExp('^#[aAbBcCdDeEfF0-9]{6}$')

export const validationMixin = {
  /* filter for typical currencies */

  methods: {
    isEmptyRule (value) {
      if (!value && value !== 0) {
        return i18n.tc('validations.inputRequired')
      } else {
        return true
      }
    },
    isColorRule (value) {
      if (value && !COLORPATTERN.test(value)) {
        return i18n.tc('validations.inputHexRequired')
      }
      return true
    },
    isIntegerRule (value) {
      if (value && !Number.isInteger(value)) {
        return i18n.tc('validations.inputIntegerNumberRequired')
      }
      return true
    },
    isNumberRule (value) {
      if (value && isNaN(value)) {
        return i18n.tc('validations.inputNumberRequired')
      }
      return true
    },
    isDateRangeRule (value) {
      const date = value.split(' - ')
      const lowerDate = date[0]
      const upperDate = date[1]
      if (date.length < 2 || lowerDate === '' || upperDate === '') {
        return i18n.tc('validations.inputRangeDatesRequired')
      }
      return true
    }
  }
}
